import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { useLocation, useHistory } from "react-router-dom";
import LazyLoad from "react-lazyload";

import "./widget.scss";

const ContactForm = ({ sect }) => {
  const history = useHistory();

  const location = useLocation();
  const [currentPageSlug, setCurrentPageSlug] = useState("");

  useEffect(() => {
    const pathArray = location.pathname;

    setCurrentPageSlug(pathArray);
  }, [location]);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_fjk1njd",
        "template_54yuk4m",
        e.target,
        "BX0WtIBtVDQ5s29L9"
      )
      .then(
        () => {
          history.push("/thankyou");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <LazyLoad height={200} once>
      <div
        id="contact-form-section"
        className="rn-contact-page ptb--120 "
        style={{ paddingTop: "50px" }}
      >
        <div className="contact-form--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 order-1 order-lg-2">
                {/* Start Single Address  */}
                <div className=" col-md-8 col-sm-7 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <FiHeadphones />
                    </div>
                    <div className="inner">
                      <h4 className="title">{sect.emailTitle}</h4>
                      <p>
                        <a href="mailto:joe@omtechmasters.co.uk">
                          {sect.emailAdd}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className=" col-md-8 col-sm-7 col-12 mt_mobile--50">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMail />
                    </div>
                    <div className="inner">
                      <h4 className="title">{sect.phoneTitle} </h4>
                      <p>
                        <a href="tel:0116 393 0838">{sect.phoneNumber}</a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className=" col-md-8 col-sm-7 col-12 mt_md--50 mt_sm--50">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMapPin />
                    </div>
                    <div className="inner">
                      <h4 className="title">{sect.locationTitle}</h4>
                      <p>{sect.LocationAdd}</p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}
              </div>
              <div className="col-lg-6 order-1 order-lg-2 ptb--20">
                <div className="section-title text-left mb--50">
                  <h2
                    className="title"
                    style={{ fontSize: "40px", paddingTop: "40px" }}
                  >
                    {sect.formTitle}
                  </h2>
                  <p className="description" style={{ paddingTop: "50px" }}>
                    {sect.formDescription}
                  </p>
                </div>
                <div className="form-wrapper">
                  <form action="" onSubmit={sendEmail}>
                    <div className="rn-form-group">
                      <input
                        type="text"
                        name="fullname"
                        placeholder="Your Name"
                        required
                      />
                    </div>

                    <div className="rn-form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Your E-mail"
                        required
                      />
                    </div>

                    <div className="rn-form-group">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Your Phone"
                      />
                    </div>

                    <div className="rn-form-group">
                      <textarea
                        name="message"
                        placeholder="How can we help?"
                      ></textarea>
                    </div>

                    <div className="rn-form-group">
                      <button
                        className="rn-button-style--2 btn-solid"
                        type="submit"
                        value="submit"
                        name="submit"
                        id="mc-embedded-subscribe"
                      >
                        GET A QUOTE NOW !
                      </button>
                    </div>

                    <div className="rn-form-group">
                      <input
                        type="hidden"
                        name="pageSlug"
                        value={currentPageSlug}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{ paddingTop: "80px", marginRight: "0", marginLeft: "0" }}
          >
            <div className="col-md-12 text-center">
              <div className="row align-items-center row-cols-1 row-cols-sm-2 row-cols-md-6 row-cols-lg-6 row-cols-xl-6">
                <div className="col">
                  <div className="row clutch-review-bottom">
                    <div className="col-md-12">
                      <a href="/clutch-review/">
                        <img
                          src="/assets/images/clutch/clutch-review-widget.png"
                          alt="clutch review"
                          width="250px"
                          height="64px"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col">
                <div className="row clutch-review-bottom">
                  <div className="col-md-12">
                    <div>
                      <a target="_blank" href="https://www.goodfirms.co/company/omtech-masters">
                        <img  src="https://assets.goodfirms.co/badges/color-badge/top-website-development-companies.svg" title="Top Web Development Company" alt="Top Web Development Company on GoodFirms" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row clutch-review-bottom">
                  <div className="col-md-12">
                    <a href="/clutch-review/">
                      <img
                        className="width"
                        src="/assets/images/clutch/optitop_clutch.co_web_developers_leicester_2024.webp"
                        alt="clutch top"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row clutch-review-bottom">
                  <div className="col-md-12">
                    <a target="_blank" href="https://techreviewer.co/companies/omtech-technologies" >
                      <img src="/assets/images/top-web-uk.svg" alt="techreviewer top ratings" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row clutch-review-bottom">
                  <div className="col-md-12">
                    <a href="https://www.sortlist.com/agency/omtechmasters" target="_blank">
                      <img src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-stars-blue-light-xl.svg" alt="stars" />
                    </a>
                  </div>
                </div>
              </div>
                <div className="col">
                  <div className="row">
                    <div className="col-md-12">
                      <a
                        href="https://www.peopleperhour.com/freelancer/technology-programming/joe-itzcovitch-top-rated-php-web-development-zaqajqv#reviews"
                        rel="nofollow"
                        title="Check our reviews on PeoplePerHour"
                        className="button"
                        target="_blank"
                      >
                        <div className="pph-review">
                          <div className="container clearfix">
                            <div className="member-info boxmodelfix without-hourlies">
                              <div className="image">
                                <img
                                  src="https://dw3i9sxi97owk.cloudfront.net/uploads/thumbs/c29ea5064c2b5f6fd77c6274123a8dfe_150x150.jpg"
                                  alt=""
                                  width="61px"
                                  height="61px"
                                />
                              </div>
                              <div className="head">
                                <div className="name crop">Joe I.</div>
                                <div className="badges widget-member-prizes">
                                  <img
                                    className="prize"
                                    title="Top Endorsed"
                                    src="https://dw3i9sxi97owk.cloudfront.net/uploads/prizes/badges/ffdd1b856a16c89191f1150e4430076b.png"
                                    alt="Top Endorsed"
                                  />
                                </div>
                                <div className="identity clearfix">
                                  <ul>
                                    <li className="job-title crop">
                                      Top rated PHP Web Development | WordPress
                                      | Magento | Drupal | OpenCart | PrestaShop
                                      | Joomla
                                    </li>
                                    <li className="feedback crop">
                                      Rating: 100%
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="hire-button">
                                <a
                                  href="https://www.peopleperhour.com/freelancer/technology-programming/joe-itzcovitch-top-rated-php-web-development-zaqajqv#reviews"
                                  rel="nofollow"
                                  title="Check our reviews on PeoplePerHour"
                                  className="button"
                                  target="_blank"
                                >
                                  <span>Check our reviews on </span>
                                  <span className="logo">PeoplePerHour</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LazyLoad>
  );
};

export default ContactForm;
